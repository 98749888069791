.collapse {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  margin-top: 12px;
  border-bottom: 0;
}
.collapse-trigger {
  position: relative;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  border-bottom: 1px solid #d9d9d9;
  transition: 0.3s;
  cursor: pointer;
}
.collapse-trigger:hover {
  color: black;
}
.collapse-trigger:before {
  content: "";
  border: solid rgba(0, 0, 0, 0.4);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2.5px;
  margin-right: 12px;
  transform: rotate(-45deg);
  position: relative;
  top: -2px;
  left: -2px;
  transition: 0.3s;
}
.collapse-trigger[aria-expanded=true]:before {
  transform: rotate(45deg);
  left: 0;
}
.collapse-trigger[aria-disabled=true] {
  opacity: 0.4;
  cursor: auto;
}
.collapse-panel {
  padding: 0 16px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.collapse-panel[aria-expanded=true] {
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
}
